<template>
  <div class="p-grid">
    <Toast />
    <Card class="style p-col-2">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div class="p-inputgroup">
            <div>
              <h3 class="p-mt-0"><strong>{{ _t('label_instruction_files') }}</strong></h3>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <TreeTable
            v-model:expandedKeys="expandedOrganizations"
            v-model:selectionKeys="selectedNode"
            :autoLayout="true"
            :scrollable="true"
            :value="organizations"
            class="p-treetable-sm"
            scrollHeight="85vh"
            selectionMode="single"
            stripedRows
            style="margin: -1.5rem; font-size: 12px; padding: 0 5px 0 0;!important; height:85vh;"
            @node-expand="appendNode($event)"
            @node-select="setOrgId($event)"
        >
          <Column :expander="true" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
            <template #body="slotProps">
              <i :class="getOrgIcon(slotProps.node.data.category)+' p-mr-2 color-green'" style="font-size:1.5em;"/>
              {{ slotProps.node.data.name }}
            </template>
          </Column>
        </TreeTable>
      </template>
    </Card>
    <transition name="slide-fade">
      <Card class="style p-col">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <h3 class="p-m-0" style="width: 100%">
              <strong v-if="getIsEditable">{{ _t('label_Manage_attachments') }}</strong>
              <strong v-else>{{ _t('menu.attachments') }}</strong>
            </h3>
            <Button
                v-tooltip.bottom="_t('label_List_view')"
                :class="{
              'p-button-success': getViewMode === 'list',
              'p-button-secondary': getViewMode !== 'list',
            }"
                class="p-button-rounded p-button-text"
                icon="mdi mdi-view-list"
                @click="setViewMode('list')"
            />
            <Button
                v-tooltip.bottom="_t('label_Grid_view')"
                :class="{
              'p-button-success': getViewMode === 'grid',
              'p-button-secondary': getViewMode !== 'grid',
            }"
                class="p-button-rounded p-button-text"
                icon="mdi mdi-view-grid"
                @click="setViewMode('grid')"
            />
            <!--          <div class="p-inputgroup p-mr-4" style="max-width: 250px">-->
            <!--            <InputText v-model="filter" clearable placeholder="Search" @change="filterFiles" />-->
            <!--            <Button icon="pi pi-search" class="p-button-success" @click="filterFiles"/>-->
            <!--          </div>-->
            <Button
                v-if="getIsEditable"
                v-tooltip.bottom="_t('label_Upload_file')"
                class="p-button-success p-button-text p-button-rounded"
                icon="pi pi-plus"
                @click="uploadDialog = true"
            />
            <Button
                v-if="getIsEditable"
                v-tooltip.bottom="_t('label_Change_folder')"
                :disabled="!getSelectedFiles.length"
                class="p-button-success p-button-text p-button-rounded"
                icon="mdi mdi-find-replace"
                @click="moveDialog = true"
            />
            <Button
                v-if="!getIsEditable"
                v-tooltip.bottom="_t('label_edit')"
                class="p-button-success p-button-text p-button-rounded"
                icon="pi pi-pencil"
                @click="setIsEditable(true)"
            />
            <Button
                v-if="getIsEditable"
                v-tooltip.bottom="_t('Cancel')"
                class="p-button-danger p-button-text p-button-rounded"
                icon="pi pi-times"
                @click="setIsEditable(false)"
            />
            <Button
                v-if="getIsEditable"
                v-tooltip.bottom="_t('label_delete')"
                :disabled="!getSelectedFiles.length"
                class="p-button-danger p-button-text p-button-rounded"
                icon="pi pi-trash"
                @click="removeSelectedFiles"
            />
          </div>
        </template>
        <template #content>
          <div class="p-d-flex">
            <DeferredContent style="width: 100%" @load="onDataLoad">
              <div
                  v-for="(file, fileIndex) in getFiles.sort((a, b) => a.name.localeCompare(b.name))"
                  :key="fileIndex"
                  :class="{
                  'p-d-inline-flex p-flex-column p-mb-4 p-shadow-1': getViewMode === 'grid',
                  'p-d-flex p-flex-row': getViewMode === 'list',
                }"
                  class="p-ai-start p-mr-4 p-pb-4 file"
              >
                <template v-if="getViewMode==='grid'">
                  <div class="file-controls" style="width: 100%">
                    <Checkbox v-if="getIsEditable" v-model="file.selected" :binary="true"
                              class="file-controls__checkbox"/>
                    <!--                  <Button icon="pi pi-times" class="file-controls__remove p-button-rounded p-button-danger p-button-text" disabled v-if="getIsEditable" />-->
                  </div>
                  <div :class="{ 'hover': file.hovered }" class="file-icon p-text-center"
                       style="width: 100%; min-height: 50px"
                       @click="downloadFile(file.path)"
                       @mouseleave="file.hovered = false"
                       @mouseover="file.hovered = true"
                  >
                    <i
                        :class="{ 'pi pi-download': file.hovered, [file.icon]: !file.hovered }"
                        style="font-size: 3rem"
                    ></i>
                  </div>
                  <div
                      v-tooltip.bottom="file.name"
                      class="file-filename p-d-block p-text-center"
                      style="width: 100%"
                  >
                    {{ file.name }}
                  </div>
                </template>
                <template v-if="getViewMode==='list'">
                  <div class="p-d-inline-flex">
                    <Checkbox v-if="getIsEditable" v-model="file.selected" :binary="true"
                              class="file-controls__checkbox p-mr-2"/>
                  </div>
                  <div class="p-d-inline-flex">
                    <i :class="file.icon" style="font-size: 1.35rem"></i>
                  </div>
                  <div
                      v-tooltip.bottom="file.name"
                      class="p-d-inline-flex"
                      style="width: 100%"
                  >
                    {{ file.name }}
                  </div>
                  <div class="p-d-inline-flex">
                    <Button
                        class="file-controls__remove p-button-rounded p-button-success p-button-text p-p-0"
                        icon="pi pi-download"
                        @click="downloadFile(file.path)"/>
                  </div>
                </template>
              </div>
            </DeferredContent>
          </div>
        </template>
      </Card>
    </transition>
  </div>
  <Dialog v-model:visible="uploadDialog" :header="_t('INSTRUCTION_UPLOAD')" style="min-width: 500px">
    <FileUpload :auto="true"
                :customUpload="true"
                :fileLimit="5"
                :maxFileSize="200*1024*1024*1024"
                :multiple="true"
                :showCancelButton="false" :showUploadButton="false"
                @beforeSend="onUpload" @beforeUpload="onUpload"
                @error="onUpload" @progress="onProgress"
                @upload="onUpload" @uploader="onUploadFiles"
                :choose-label="_t('label_Select_file')"
    >
      <template #empty>
        <p>{{ _t('label_Drag_and_drop') }}</p>
      </template>
    </FileUpload>
  </Dialog>
  <Dialog v-model:visible="moveDialog" :header="_t('label_Change_folder')">
    <p>{{ _t('label_Select_folder') }}</p>
    <OrganizationLink v-model="selectedOrgToMove" :editMode="true" :required-field="true"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-danger" @click="moveDialog=false"/>
      <Button :label="_t('label_Change_folder')" autofocus class="p-button-success" @click="moveToOrg"/>
    </template>
  </Dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getTree} from '@/api/tree';
import OrganizationLink from '../../components/ixarma/OrganizationLink'
import {downloadInstruction} from '@/api/instruction';

export default {
  components: {OrganizationLink},
  data: () => ({
    expandedOrganizations: [],
    selectedNode: {},
    organizations: [],
    orgCategories: [],
    checked: false,
    filter: '',
    selectedOrdering: {label: 'Ascending', value: 'asc', icon: 'pi pi-sort-alpha-down'},
    ordering: [{label: 'Ascending', value: 'asc', icon: 'pi pi-sort-alpha-down'}, {
      label: 'Descending',
      value: 'desc',
      icon: 'pi pi-sort-alpha-up'
    }],
    uploadDialog: false,
    moveDialog: false,
    selectedOrgToMove: {
      orgId: null,
      orgName: '',
    },
  }),
  mounted() {
    this.orgCategories =  [
      {value: 'GEOGRAPHICAL_SCOPE', label: this._t('GEOGRAPHICAL_SCOPE'), icon: 'mdi mdi-earth'},
      {value: 'BUILDING', label: this._t('BUILDING'), icon: 'mdi mdi-home'},
      {value: 'COMPANY_ORGANISATION', label: this._t('COMPANY_ORGANISATION'), icon: 'mdi mdi-sitemap'},
      {value: 'ALARM_GROUP_ORGANISATION', label: this._t('ALARM_GROUP_ORGANISATION'), icon: 'mdi mdi-deskphone'},
      {value: 'ALARM_COLLECTING_POINTS_GROUP', label: this._t('ALARM_COLLECTING_POINTS_GROUP'), icon: 'mdi mdi-phone-in-talk'},
      {value: 'PART_OF_BUILDING', label: this._t('PART_OF_BUILDING'), icon: 'mdi mdi-office-building-outline'},
      {value: 'ROOM', label: this._t('ROOM'), icon: 'mdi mdi-floor-plan'},
      {value: 'GROUP_OF_INSTALLATIONS', label: this._t('GROUP_OF_INSTALLATIONS'), icon: 'mdi mdi-information'},
      {value: 'HARDWARE_GROUP', label: this._t('HARDWARE_GROUP'), icon: 'mdi mdi-router-wireless'}
    ]
    this.getOrganizations()
    this.callFiles(this.filter);
  },
  methods: {
    ...mapActions({
      'callFiles': 'attachments/callFiles',
      'removeFiles': 'attachments/removeFiles',
      'uploadFile': 'attachments/uploadFile',
      'multiplyUpload': 'attachments/multiplyUpload',
      'moveToOrganization': 'attachments/moveToOrganization',
    }),
    ...mapMutations({
      'setSorting': 'attachments/setSorting',
      'setOrganization': 'attachments/setOrganization',
      'setIsEditable': 'attachments/setIsEditable',
      'setViewMode': 'attachments/setViewMode',
    }),
    appendNode(item) {
      getTree(item.key).then((response) => {
        item.children = response.data
            .map(({name, category, hasChildren, id}) => ({
              data: {name, category},
              leaf: !hasChildren,
              key: id,
              parentId: item.key
            }))
      })
    },
    getOrganizations() {
      getTree().then((response) => {
        this.organizations = response.data
            .map(({name, category, hasChildren, id}) => ({
              data: {name, category},
              leaf: !hasChildren,
              key: id,
              parentId: null
            }))
        if (this.organizations.length > 0) {
          this.appendNode(this.organizations[0])
          this.expandedOrganizations[this.organizations[0].key] = true;
          this.selectedNode[this.organizations[0].key] = true;
        }
      })
    },
    getOrgIcon(category) {
      let orgCategory = this.orgCategories.find(item => item.value == category)
      if (orgCategory) {
        return orgCategory.icon
      }
      return ''
    },
    setOrgId(org) {
      this.setOrganization(org.key);
    },
    onDataLoad: function () {
      console.log('load');
    },
    removeSelectedFiles() {
      if (this.getSelectedFiles.length) {
        this.$confirm.require({
          message: this._t('label_confirm_deletion'),
          header: this._t('label_Confirmation'),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this._t('label_yes'),
          rejectLabel: this._t('label_no'),
          accept: () => {
            this.removeFiles();
          }
        });
      }
    },
    onUploadFiles(event) {
      // single
      event.files.map(file => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('orgId', this.getOrganization);
        this.uploadFile(formData).catch((err => {
            if(err.response.data.reason) {
                this.$toast.add({severity:'error', summary: this._t('label_error'), detail: err.response.data.reason, life: 3000});
                this.uploadDialog = false;
            }
        }));
      });
      // multiple
      // event.files.map(file => {
      //   formData.append('file', file);
      //   // formData.append('files[]', file);
      // });
      // formData.append('orgId', this.getOrganization);
      // this.multiplyUpload(formData);
    },
    onUpload(event) {
      console.log(event);
      alert('onUpload');
    },
    onProgress(event) {
      console.log(event);
      alert('onProgress');
    },
    moveToOrg() {
      if (this.getSelectedFiles.length) {
        this.moveToOrganization(this.selectedOrgToMove.orgId)
            .then(() => {
              this.moveDialog = false;
            });
      }
    },
    downloadFile(filename) {
      downloadInstruction(filename);
    }
  },
  computed: {
    ...mapGetters({
      'getFiles': 'attachments/getFiles',
      'getOrganization': 'attachments/getOrganization',
      'getIsEditable': 'attachments/getIsEditable',
      'getViewMode': 'attachments/getViewMode',
      'getSelectedFiles': 'attachments/getSelectedFiles',
    }),
  },
}
</script>

<style lang="scss" scoped>
.p-flex-column {
  &.file {
    max-width: 130px;
    max-height: 150px;
    width: 100%;
    height: 100%;
  }
}

.p-flex-row {
  &.file {
    max-width: 100%;
    max-height: 30px;
    width: 100%;
    height: 100%;
    padding: 5px;

    .file-controls__remove {
      margin-top: -7px;
    }
  }
}

.file {
  border: 1px solid rgba(207, 211, 224, 0.6);
  overflow: hidden;

  &:hover {
    background-color: rgba(104, 159, 56, 0.3);
  }

  .file-controls {
    position: relative;
    min-height: 40px;

    .file-controls__remove,
    .file-controls__checkbox {
      position: absolute;
    }

    .file-controls__checkbox {
      top: 10px;
      left: 10px;
    }

    .file-controls__remove {
      top: 5px;
      right: 5px;
    }
  }

  .file-filename {
    overflow: hidden;
    margin: 10px 10px 0 10px;
    max-width: 110px;

  }
}

.file-icon.hover {
  cursor: pointer;
}

::v-deep(.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td) {
  padding: 0;
}

::v-deep(.p-fileupload-files .p-fileupload-filename) {
  width: 100%;
}

::v-deep(.p-fileupload-files .p-button.p-component.p-button-icon-only) {
  display: none;
}

::v-deep(.p-fileupload-content) {
  min-height: 150px;
  text-align: center;
  padding-top: 50px;
}
</style>
